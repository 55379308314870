import React from "react";
import Home from "./containers/Home";
import TV from "./containers/TV";
import "./App.scss";

function App() {
  let content;

  if (
    window.location.href.endsWith("/text") ||
    window.location.href.endsWith("/text/")
  ) {
    content = <TV textOnly />;
  } else if (
    window.location.href.endsWith("/tv") ||
    window.location.href.endsWith("/tv/")
  ) {
    content = <TV />;
  } else {
    content = <Home />;
  }

  return (
    <div className="App">
      <div className="App-header">{content}</div>
    </div>
  );
}

export default App;
