import React from "react";
import JokeForm from "../components/JokeForm";
import JamesJokesLogo from "../images/JamesJokes.svg";
import "./Home.scss";

const Home = () => {
  return (
    <div className="home">
      <div className="home-logo">
        <img src={JamesJokesLogo} alt="Logo" />
      </div>
      <JokeForm />
    </div>
  );
};

export default Home;
