import axios from "axios";

const prefixRoute = (route: string) => {
  return process.env.NODE_ENV === "production"
    ? `https://www.jamesjokes.com/api/${route}`
    : `http://localhost:5000/api/${route}`;
};

class AxiosService {
  async get(route: string) {
    const prefixedRoute = prefixRoute(route);

    return axios.get(prefixedRoute);
  }

  async post(route: string, body: object) {
    const prefixedRoute = prefixRoute(route);

    return axios.post(prefixedRoute, body);
  }
}

export const axiosService = new AxiosService();
