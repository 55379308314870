import React from "react";
import "./ImageViewer.scss";

const ImageViewer = ({ source }: { source: string }) => {
  return (
    <div className="image-viewer-container">
      <img className="image-viewer" src={source} alt="james-funny-pic" />
    </div>
  );
};

export default ImageViewer;
