import React, { useState } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { axiosService } from "../axios-service";
import "./JokeForm.scss";

const YouTubeRegexp = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*?[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi;

enum JokeType {
  Text = "text",
  Image = "image",
  YouTube = "youtube",
}

type JokeSubmissionData = {
  type: JokeType;
  content: string;
};

type InsertJokeApiResponse = {
  inserted: boolean;
};

const JokeForm = () => {
  const [type, setType] = useState("text");
  const [content, setContent] = useState("");
  const [showSubmitSuccess, setShowSubmitSuccess] = useState(false);
  const [showContentMissingError, setShowContentMissingError] = useState(false);
  const [showYoutubeError, setShowYoutubeError] = useState(false);
  const [showImageError, setShowImageError] = useState(false);

  const handleTypeChange = (event: any) => {
    setType(event.target.value);
  };

  const handleContentChange = (event: any) => {
    setContent(event.target.value);
  };

  const handleFormSubmissionValidation = () => {
    switch (type) {
      case JokeType.Text:
        if (content.length < 5) {
          setShowContentMissingError(true);
          setShowImageError(false);
          setShowYoutubeError(false);
        } else {
          submitForm({
            type,
            content,
          });
        }
        break;
      case JokeType.Image:
        if (
          !/(\.png|\.jpg|\.jpeg|\.gif)$/.test(content) ||
          !/^(http:\/\/|https:\/\/)/.test(content)
        ) {
          setShowImageError(true);
          setShowYoutubeError(false);
          setShowContentMissingError(false);
        } else {
          submitForm({
            type,
            content,
          });
        }
        break;

      case JokeType.YouTube:
        const match = YouTubeRegexp.exec(content);

        if (!match || match.length < 1) {
          setShowYoutubeError(true);
          setShowImageError(false);
          setShowContentMissingError(false);
        } else {
          submitForm({
            type,
            content,
          });
        }
        break;
    }
  };

  const submitForm = (submissionData: JokeSubmissionData) => {
    axiosService
      .post("insert-joke", submissionData)
      .then(({ data }) => {
        setShowSubmitSuccess(true);
        setShowContentMissingError(false);
        setShowImageError(false);
        setShowYoutubeError(false);
        setContent("");
        window.setTimeout(() => {
          setShowSubmitSuccess(false);
        }, 5000);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let description;

  switch (type) {
    case JokeType.Image:
      description = "Image URL";
      break;

    case JokeType.YouTube:
      description = "Youtube URL";
      break;

    default:
      description = "Write anything!";
      break;
  }

  return (
    <div className="joke-form">
      <div className="heading">Submit a Joke, Image or YouTube</div>
      <FormControl variant="outlined">
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={type}
          onChange={handleTypeChange}
        >
          <MenuItem value="text">Joke Text</MenuItem>
          <MenuItem value="image">Image URL</MenuItem>
          <MenuItem value="youtube">Youtube URL</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined">
        <TextField
          className="joke-form-text"
          variant="outlined"
          id="outlined-textarea"
          label={description}
          multiline
          value={content}
          onChange={handleContentChange}
          error={showContentMissingError || showYoutubeError || showImageError}
        />
        <div className={`errorMessage ${showContentMissingError && "show"}`}>
          Add some longer fucking content.
        </div>
        <div className={`errorMessage ${showYoutubeError && "show"}`}>
          Youtube URL must look like
          'https://www.youtube.com/watch?v=dQw4w9WgXcQ'
        </div>
        <div className={`errorMessage ${showImageError && "show"}`}>
          Image URL must end in .png, .jpg, .jpeg, .gif and start with http://
          or https://
        </div>
      </FormControl>
      <FormControl>
        <Button
          className="joke-form-button"
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleFormSubmissionValidation}
        >
          Submit Joke
        </Button>
      </FormControl>
      <div className={`successMessage ${showSubmitSuccess && "show"}`}>
        Joke Submitted Successfully!
      </div>
    </div>
  );
};

export default JokeForm;
