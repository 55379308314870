import React, { FC } from "react";
import "./YouTubeViewer.scss";

const YouTubeRegexp = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*?[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi;

interface Props {
  url?: string;
}

const YouTubeViewer: FC<Props> = ({ url }) => {
  let youtubeID;

  if (url) {
    var match = YouTubeRegexp.exec(url);

    if (match && match.length > 1) {
      youtubeID = match[1];
    }
  }

  let playlist;

  const finalURL = `https://www.youtube.com/embed/${
    youtubeID || "dQw4w9WgXcQ"
  }?rel=0&amp;autoplay=1;fs=0;autohide=0;hd=0;loop=1;${
    playlist ? `listType=playlist;list=${playlist};` : ""
  }playlist=${playlist ? playlist : youtubeID}`;

  return (
    <div className="youtube-container">
      <iframe
        className="youtube-iframe"
        src={finalURL}
        title="YouTube!"
      ></iframe>
    </div>
  );
};

export default YouTubeViewer;
