import React, { FC } from "react";
import "./TextJokeViewer.scss";

interface Props {
  text?: string;
}

const TextJokeViewer: FC<Props> = ({ text }) => {
  return (
    <div className="text-joke-container">
      <div
        className={`text-joke ${
          text && text.length > 100 && text.length <= 200 && "medium-text"
        } ${text && text.length > 200 && "small-text"}`}
      >
        {text}
      </div>
    </div>
  );
};

export default TextJokeViewer;
