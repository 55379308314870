import React, { FC, useEffect, useState } from "react";
import { axiosService } from "../axios-service";
import TextJokeViewer from "../components/TextJokeViewer";
import TextJoke from "../components/TextJokeViewer";
import YouTubeViewer from "../components/YouTubeViewer";
import ImageViewer from "../components/ImageViewer";
import "./TV.scss";

const minuteInMilliseconds = 60000;

enum JokeType {
  Text = "text",
  Image = "image",
  YouTube = "youtube",
}

type RandomJokeResponse = {
  type: JokeType;
  content: string;
};

interface Props {
  textOnly?: boolean;
}

const BaseComponent = () => {
  return <TextJokeViewer text="Visit JamesJokes.com to add a joke. 🐊" />;
};

const determineContentComponent = (response: RandomJokeResponse) => {
  const { type, content } = response;
  let contentComponent: React.ReactChild;

  switch (type) {
    case JokeType.Text:
      contentComponent = <TextJoke text={content} />;
      break;
    case JokeType.Image:
      // add image component
      contentComponent = <ImageViewer source={content} />;
      break;
    case JokeType.YouTube:
      // add youtube component
      contentComponent = <YouTubeViewer url={content} />;
      break;
    default:
      contentComponent = <BaseComponent />;
      break;
  }

  return contentComponent;
};

let content = "";

const TV: FC<Props> = ({ textOnly }) => {
  const [showContent, setShowContent] = useState(true);

  const [contentComponent, setContentComponent] = useState<React.ReactChild>(
    <TextJoke text="Visit JamesJokes.com to add a joke. 🐊." />
  );

  const fetchJoke = () => {
    axiosService
      .get(textOnly ? "random-text-joke" : "random-joke")
      .then((response: { data: RandomJokeResponse }) => {
        console.log(response.data.content.length, content.length);
        if (response.data.content && response.data.content !== content) {
          content = response.data.content;
          setShowContent(false);

          window.setTimeout(() => {
            console.log("setTimeout");
            setContentComponent(determineContentComponent(response.data));
            setShowContent(true);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchJoke();

    const jokeInterval = window.setInterval(
      () => {
        fetchJoke();
      },
      textOnly ? 10000 : 25000
    );

    return () => {
      clearInterval(jokeInterval);
    };
  }, []);

  return (
    <div className={`tv-container`}>
      <div className={`tv-content ${showContent && "show"}`}>
        {contentComponent}
      </div>
    </div>
  );
};

export default TV;
